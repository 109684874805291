.envelope {
  position: relative;
  outline: none;
}

.indicator {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
}
