@import "theme/colors";

.status {
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 132px;
  white-space: nowrap;

  &--sent,
  &--negotiating {
    font-weight: bold;
  }

  &--canceled,
  &--declined,
  &--expired {
    color: $grayishBlue;
    font-weight: 600;
  }

  &--hired {
    background: rgba(255, 163, 26, 0.05);
    color: $secondary;
    font-weight: bold;
    border-radius: 8px;
  }

  &--active {
    background: rgba(86, 99, 113, 0.05);
    border-radius: 8px;
    color: $darkBlue;

    font-weight: bold;
    font-size: 14px;
  }
}
