@import "theme/colors";

.textButtonsContainer {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}

.searchContainer {
  margin: 8px 0 !important;
}

.searchInputContainer {
  margin: 0 32px;
}

.searchInput {
  border-radius: 42px !important;
  max-width: 100% !important;
}
