$max: 120;
$offset: 4;
$unit: "px";

@mixin class-step-generator($className, $styleName) {
  $i: 0;

  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }
    $i: $i + $offset;
  }
}

@include class-step-generator(".p-", "padding");
@include class-step-generator(".pl-", "padding-left");
@include class-step-generator(".pr-", "padding-right");
@include class-step-generator(".pt-", "padding-top");
@include class-step-generator(".pb-", "padding-bottom");

@include class-step-generator(".m-", "margin");
@include class-step-generator(".ml-", "margin-left");
@include class-step-generator(".mr-", "margin-right");
@include class-step-generator(".mt-", "margin-top");
@include class-step-generator(".mb-", "margin-bottom");

.strong {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

@function rgba-to-rgb($rgba, $background: #fff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%);
}
