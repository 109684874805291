@import "theme/colors";

.container {
  cursor: pointer;
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  font-weight: normal;
  align-items: center;
  padding-bottom: 2px;

  &.disabled {
    opacity: 0.5;
  }
}

.radio {
  width: 24px;
  height: 24px;
  border: 2px solid $grayishBlue;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.radio--checked {
  border-color: $secondary;
  background-color: $secondary;
}

.label::-ms-check {
  display: none;
}

.label {
  -webkit-appearance: none;
}
