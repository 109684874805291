.tabs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.tab {
  width: 50%;
  margin: 12px 0;
  box-sizing: border-box;
  text-align: center;
}

.drawerContainer {
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
