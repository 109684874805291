@import "theme/colors";
@import "theme/breakpoints";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .container {
    min-height: 56px;
  }
}

.container {
  background: linear-gradient(102.28deg, $darkBlue 3.24%, $midnightBlue 77.28%);
  color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 80px 25px;
  font-weight: 500;
}

.columnContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: right;
}

.imageContainer {
  flex: 1;
  width: 32px;
  height: 32px;
}

.category {
  padding-bottom: 24px;
  color: $grayishBlue;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  flex: 2;
}

.info {
  display: flex;
  flex: 3;
  justify-content: flex-end;
  color: $grayishBlue;
  white-space: nowrap;
}

.link {
  padding-bottom: 15px;
  cursor: pointer;
  background: none;
  color: white;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (max-width: $tabletWidth) {
  .container {
    padding: 40px 40px 25px;
  }

  .info {
    flex: 2;
  }

  .columnContainer,
  .imageContainer {
    padding-right: 20px;
  }
}

@media screen and (max-width: $mobileWidth) {
  .container {
    padding: 40px 40px 0px;
    height: 375px;
    flex-direction: column;
  }

  .linksContainer {
    flex-direction: row;
    padding: 40px 0px 15px;
  }

  .info {
    align-self: center;
    padding-top: 40px;
  }
}
