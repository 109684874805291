@import "theme/colors";

.dropdown {
  position: relative;
  display: inline-block;
}

.ul {
  list-style-type: none;
  padding: 0;
  top: 5px;
  right: 0;
  background-color: $white;
  position: absolute;
  z-index: 1;

  li a,
  li button {
    color: $darkBlue;
    text-decoration: none;
    border: none;
    padding: 8px 20px;
    font-size: 12px;
    flex: 1;
    text-align: start;

    &:hover {
      cursor: pointer;
      background-color: #e5e5e5;
    }
  }

  li {
    width: 150px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
  }

  li:last-child {
    border-bottom: none;
  }
}
