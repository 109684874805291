@import "theme/colors";
@import "theme/breakpoints";

.container {
  @media screen and(max-width:$tabletWidth) {
    min-height: 172px;
  }
}
.headerContainer {
  display: flex;
  flex-direction: column;
  @media screen and(max-width:$tabletWidth) {
    max-width: calc(100vw - 32px) !important;
    margin-top: -32px;
    margin-left: 26px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.titleDescription {
  max-width: 90%;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  @media screen and(max-width:$tabletWidth) {
    margin-top: 8px;
    max-width: 100%;
  }
}
