@import "theme/colors";

.container {
  margin-bottom: 140px;
}

.listTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  width: 232px;
}

.listTitle {
  font-size: 14px;
  font-weight: bold;
}

.listSelect {
  margin-bottom: 33px;
}

.select {
  margin-bottom: 12px;
  width: 232px;
}

.dropdownContainer {
  padding-bottom: 28px;
  width: 232px;
}

.dropdownContainerTitle {
  height: 16px;
  display: flex;
  justify-content: space-between;
}

.dropdownTitle {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.dropdownIconButton {
  margin-right: -4px;
}

.optionContainer {
  margin-top: 16px;
}

.resetButton {
  color: $grayishBlue !important;
}

.searchFormCheckboxInput {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.trainedInMoCapContainer {
  margin-left: 32px;
}

.cityContainer {
  margin-bottom: 12px;
}
