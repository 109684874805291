@import "theme/colors";

.profileImage {
  margin-top: 16px;
}

.gdprText {
  font-size: 16px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: $darkGrey;
  font-style: normal;
  margin: 16px 0px;
  padding: 0px 6px;
}

.gdprContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 32px;
}

.contentCheckbox {
  min-width: 0px;
  padding-top: 2px;
}
