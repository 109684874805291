.container {
  position: relative;
  display: inline-block;
  pointer-events: none;
  vertical-align: middle;
}

.icon {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.verticalFlippedIcon {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
