@import "theme/colors";

p {
  margin: 0;
}

.quote {
  padding: 0 0 25px 0;
  font-family: Inter;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: $white;
}

.author {
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: $white;
}

.title {
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: $white;
  opacity: 0.6;
}
