.container {
  width: 100%;
}

.fileContainer {
  box-shadow: 0 2px 8px rgba(5, 21, 40, 0.05);
  border-radius: 8px;
  background: white;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;

  &--simpleButton {
    padding: 4px 4px 4px 8px;
    border-radius: 8px;
    background: white;
    color: black;
    margin-right: 16px;
  }
}

.hoverIcon {
  width: 40px;
  padding: 6px 10px;
  border-radius: 8px;
  color: black;
  background: rgba(242, 243, 244, 0.8);
  display: inline-block;
}

.hoverIcon:hover {
  opacity: 1;
}

.inputContainer {
  margin: 0;
  white-space: nowrap;
}

.dialogSubtitle {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 16px;
}
