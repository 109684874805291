@import "theme/colors";

.farewell {
  margin-top: 60px;
  text-align: center;
}

.deletedText {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
}

.improvementText {
  margin: 50px 0 10px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
}

.homepageLink {
  display: block;
  padding: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: $link;
}
