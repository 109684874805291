@import "theme/colors";

.container {
  margin-bottom: 140px;
}

.listTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 22px;
  width: 232px;
}

.listTitle {
  font-size: 14px;
  font-weight: bold;
}

.select {
  margin-bottom: 12px;
}

.dropdownContainer {
  padding-bottom: 28px;
  width: 232px;
}

.dropdownContainerTitle {
  height: 16px;
  display: flex;
  justify-content: space-between;
}

.dropdownTitle {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.dropdownIconButton {
  margin-right: -4px;
}

.optionContainer {
  margin-top: 16px;
}

.resetButton {
  color: $grayishBlue !important;
}

.resetButtonWhite {
  color: $white !important;
}

.containerMobile {
  margin-bottom: 0;
  margin-top: 20px;
  width: 80%;
}

.dropdownContainerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.applyButton {
  margin-top: 16px;
  margin-bottom: 16px;
}
