@import "theme/colors";

.removeManager {
  background: transparent;
  color: $softBlue;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  text-align: right;
  padding: 15px 7px;
  letter-spacing: 1px;
  float: right;
}
