.iconButton {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.header {
  font-size: 14px !important;
  font-weight: 500;
}

.largeCell {
  width: 135px;
}

.mediumCell {
  width: 90px;
}

.smallCell {
  width: 5px;
}

.table {
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
}

.resultsMessage {
  min-height: 1px;
  font-size: 16px;
  line-height: 28px;
  margin-top: 40px;
  text-align: right;
  margin-right: 23px;
}

.card {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.cardWithMessage {
  margin-top: 12px;
}

.cardWithoutMessage {
  margin-top: 39px;
}

.nameCell {
  cursor: pointer;
  text-decoration: none;
}
