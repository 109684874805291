.arrow {
  position: absolute;
  top: 17px;
  right: 20px;
}

.container {
  &--tag {
    margin-bottom: 15px;
  }
}

.input {
  cursor: pointer;
}
