@import "theme/colors";

.navBar {
  background: linear-gradient(103.5deg, #051528 3.24%, #1c2a3b 77.28%);
  color: $white;
  padding: 9px 20px;
  display: flex;
  justify-content: space-between;

  &.light {
    background: transparent;
    color: $darkBlue;
  }
}

.navigationLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}
