@import "theme/colors";
@import "theme/breakpoints";

.navBar {
  background: linear-gradient(103.5deg, #051528 3.24%, #1c2a3b 77.28%);
  background-attachment: fixed;
  color: $white;
  padding: 9px 20px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  height: 54px;

  &.light {
    background: transparent;
    color: $darkBlue;
  }
}

.mobilePageTitle {
  align-self: center;
}

.mobileIconContainer {
  display: flex;
  justify-content: flex-end;
}

.mobileLogo {
  align-self: center;
}

.mobileIcon {
  margin-left: -16px;
  align-self: center;
}

.backButton {
  width: 15%;
  padding-left: 6px;
  margin-right: -6px;
  align-self: center;
}

.drawer {
  z-index: 98 !important;
}

.drawerPaper {
  background: linear-gradient(103.5deg, #051528 3.24%, #1c2a3b 77.28%);
  padding-top: 68px;
  min-width: 100%;
  min-height: 100%;
  @media screen and(min-width: $mobileWidth) {
    min-height: 480px;
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
