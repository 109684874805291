@import "theme/colors";

.name {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: $darkBlue;
}

.location {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  color: $darkGrey;
}

.viewProfileLink {
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  color: $link;
  margin: 10px 0;
}

.viewProfileLink:hover {
  cursor: pointer;
}
