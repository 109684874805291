@import "theme/colors";

.container {
  display: flex;
  justify-content: space-between;
}

.label {
  margin-bottom: 20px;

  .required,
  .optional {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: $grayishBlue;
    text-transform: none;
  }

  &--error {
    color: $error !important;

    .required {
      color: $error !important;
    }
  }

  &--disabled {
    opacity: 0.4;
  }
}
