@import "theme/colors";
@import "theme/breakpoints";

.contentContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  padding-bottom: 12px;
  max-width: 1128px;
}

.description {
  margin-top: -15px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
}

@media screen and(max-width: $tabletWidth) {
  .headerContent {
    margin: auto;
  }
}

@media screen and (max-width: $flippedMobileWidth) {
  .headerContainer {
    min-height: 88px;
    max-width: fit-content;
  }
  .headerContent {
    flex: none;
  }
  .inputContainer {
    max-width: 95%;
    margin: auto;
  }
}
