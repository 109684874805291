.formFooter {
  height: 80px;
  margin-top: 12px;
}

.projectOverview {
  margin-top: 0;
}

.projectOverviewItem {
  margin-bottom: 0;
}

.noLabel {
  margin-top: 34px;
}
