.dropdownButton {
  margin-left: 20px;
}

.title {
  flex: 1;
  margin-right: 12px;
}

.playContainer {
  display: flex;
  align-items: center;
}

.list {
  &__item {
    margin-right: 5px;

    + .list__item:before {
      content: "·";
      margin-right: 5px;
      display: inline-block;
      position: relative;
    }
  }
}
