@import "theme/colors";

.subContainer {
  display: flex;
  align-items: center;
  margin-top: -8px;
  padding-bottom: 8px;

  &--clickable {
    cursor: pointer;
  }
}

.subTitle {
  font-size: 14px;
  color: $inputPlaceholder;
  padding-left: 13px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
