@import "theme/colors";

.changeDescription {
  padding: 0 0 25px 0;
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
}
.email {
    padding: 0 0 25px 0;
    font-size: 14px;
    line-height: 24px;
    color: $darkBlue;
  }
