@import "theme/colors";

.avatar {
  border: 2px solid rgba(0, 0, 0, 0);

  img:hover {
    border: 2px solid $secondary;
  }

  &--border {
    img {
      border: 2px solid $secondary;
    }
  }
}
