@import "theme/colors";

.card {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  padding-top: 24px;
  padding-bottom: 18px;
  margin-bottom: 20px;
  margin-right: 24px;

  &--cols3:nth-child(3n) {
    margin-right: 0;
  }

  @media screen and (max-width: 1200px) {
    &:nth-child(3n) {
      margin-right: 20px;
    }

    &:nth-child(2n) {
      margin-right: 20px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(5, 21, 40, 0.05%);
  margin-top: auto;
  padding-top: 10px;
}

.details {
  padding-top: 18px;
  min-height: 150px;
}

.audioPreview {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 172px;
  display: block;
}
