.card {
  margin: 0 0 80px;
  width: 100%;
}

.auditionStatus {
  cursor: pointer;
}

.selectArrow {
  margin-left: 0;
  padding-right: 18px;
}

.smallCell {
  width: 5px;
}

.largeCell {
  width: 135px;
  padding-right: 0;
}

.mediumCell {
  width: 120px;
}

.emptyAuditionPlaceholderContainer {
  margin-bottom: 0;
  border: none;
  padding: 0;
}
