@import "theme/colors";

.container {
  padding: 60px 40px;
  border-radius: 8px;
  border: 1px dashed $grayishBlue;
  display: flex;
  flex: 1;
  margin-bottom: 100px;
}

.image {
  display: flex;
  flex: 3;
  background: rgba($grayishBlue, 0.03);
  height: 300px;
}

.content {
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  margin: 56px 0;

  &--withImage {
    padding-left: 56px;
    align-items: flex-start;
  }
}

.button {
  margin-top: 26px;
}

.secondaryButton {
  margin-top: 26px;
  margin-left: 20px;
  background: $tableHover;
  color: black;
}

.title {
  text-align: center;
}

.description {
  color: $darkGrey;
  text-align: center;
  width: 100%;
}
