@import "theme/colors";
@import "theme/breakpoints";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .root {
    right: 35%;
    top: 20%;
  }
}

.root {
  flex-shrink: 0;
  margin-left: 2px;
  position: absolute;
  @media screen and (max-width: $mobileWidth) {
    margin: auto;
  }
}

.page-number {
  color: $darkBlue;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  margin: 0 12px;
  @media screen and (max-width: $mobileWidth) {
    padding: 0px;
    margin: 0 10px;
  }
}
