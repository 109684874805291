.container {
  border: 1px solid rgba(5, 21, 40, 0.07);
  border-radius: 6px;
  background: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  margin-bottom: 8px;
}

.reorderContainer {
  padding: 18px;
  margin-bottom: 24px;
  width: 500px;
}

.informationContainer {
  margin-left: 24px;
}

.sortingIconsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
  height: 54px;
}
