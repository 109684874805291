.breadcrumps__container {
  height: 23px;
  display: flex;
  align-items: center;
  width: 100%;
}

.breadcrumps__title {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.breadcrumps__chevron {
  padding-left: 23px;
  padding-right: 23px;
}
