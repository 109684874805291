@import "theme/colors";

.link {
  text-decoration: none;
  color: $grayishBlue;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  margin: 0 23px;
  padding: 19px 4px;

  &.disabled {
    opacity: 0.5;
  }

  &.dark {
    color: $darkBlue;
  }
}
