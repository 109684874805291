@import "theme/colors";
@import "theme/breakpoints";

.chatHeader {
  padding: 12px;
  background-color: $darkBlue;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: $white;
  @media screen and (max-width: $tabletWidth) {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}

.chatHeaderText {
  color: $white;
}

.drawerContainer {
  height: 100%;
  overflow: hidden;
}
