@import "theme/colors";
@import "theme/breakpoints";

.filtersButton {
  height: 42px;
  display: flex;
  justify-content: space-around;
  align-self: center;
  width: 118px;
  margin-right: 46px;
  background-color: $white;
  border: 1px solid $inputBorder !important;
}

.select {
  @media screen and(max-width: $desktopWidth) {
    width: 80px !important;
  }
}

.selectContainer {
  @media screen and(max-width: $desktopWidth) {
    width: 155px !important;
  }
}
