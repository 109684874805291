@import "theme/colors";

.container {
  display: flex;
  flex-direction: column;
}

.footer {
  padding: 11px 0 20px;
  display: flex;
  flex: 1 1;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $secondaryLight;

  &:before {
    position: absolute;
    bottom: 70px;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(5, 21, 40, 0) 0.05%,
      rgba(5, 21, 40, 0.07) 14.98%,
      rgba(5, 21, 40, 0.07) 46.96%,
      rgba(5, 21, 40, 0.07) 87.49%,
      rgba(5, 21, 40, 0) 99.95%
    );
  }
}

.btnContainer {
  display: flex;
  flex: 1;
  max-width: 552px;
  justify-content: flex-end;
}

.btn {
  margin-left: 20px;
}
