@import "theme/colors";

.card {
  margin: auto;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  margin-bottom: 24px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.headerTitle {
  font-size: 12px !important;
  font-weight: 500;
  color: $grayishBlue !important;
  margin: 0px 0px 8px;
}

.title {
  font-size: 12px !important;
  font-weight: 500;
  color: $grayishBlue !important;
  margin: 16px 0px 8px;
}

.projectTitle {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lineDivider {
  margin: 12px 0px 0px;
}

.status {
  position: absolute;
  top: -7px;
  right: -10px;
  font-size: 12px;
  min-width: 95px;
  height: 24px;
}
