.divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(5, 21, 40, 0) 0.05%,
    rgba(5, 21, 40, 0.07) 14.98%,
    rgba(5, 21, 40, 0.07) 46.96%,
    rgba(5, 21, 40, 0.07) 87.49%,
    rgba(5, 21, 40, 0) 99.95%
  );
}
