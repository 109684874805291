@import "theme/colors";

.card {
  font-size: 14px;
  margin: 20px;
  position: relative;
}

.icon {
  margin-top: 18px;
}

.cardTitle {
  color: $darkBlue;
  font-size: 24px;
  font-style: normal;
}

.cardSubtitle {
  font-weight: 300;
  color: $grayishBlue;
  white-space: pre-line;
}

.buttonContainer {
  position: absolute;
  bottom: 5%;
}

.button:last-of-type {
  margin-bottom: 5px;
  height: 48px;
  width: 216px;
}
