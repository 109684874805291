@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  src: url("Inter-Thin.woff2") format("woff2"),
       url("Inter-Thin.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  src: url("Inter-ExtraLight.woff2") format("woff2"),
       url("Inter-ExtraLight.woff") format("woff");
}


@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  src: url("Inter-Light.woff2") format("woff2"),
       url("Inter-Light.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url("Inter-Regular.woff2") format("woff2"),
       url("Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: url("Inter-Medium.woff2") format("woff2"),
       url("Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url("Inter-SemiBold.woff2") format("woff2"),
       url("Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  src: url("Inter-Bold.woff2") format("woff2"),
       url("Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  src: url("Inter-ExtraBold.woff2") format("woff2"),
       url("Inter-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url("Inter-Black.woff2") format("woff2"),
       url("Inter-Black.woff") format("woff");
}
