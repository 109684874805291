@import "theme/colors";

.titleContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.title {
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: noWrap;
}

.description {
  margin-top: -15px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
}
