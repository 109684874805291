.container {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  font-weight: 400;
  display: flex;
  align-items: center;
  position: absolute;
  right: -21px;
  padding: 3px 8px 3px 3px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.icon {
  padding-right: 5px;
}
