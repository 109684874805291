@import "theme/colors";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .contentContainer {
    flex-basis: auto !important;
  }
}

.contentContainer {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1128px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.actionContainer {
  display: flex;
  margin-top: -16px;
}

.submitButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
}

.finishedButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  color: $inputPlaceholder;
}

.deleteButton {
  align-self: center;
  min-width: 40px;
  min-height: 40px;
  margin-left: 16px;
  background-color: $darkGrey;
  cursor: pointer;
}

.editButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
}
