@import "theme/colors";

.container {
  display: flex;
  align-items: center;
}

.playContainer {
  display: flex;
  align-items: center;
}

.audioPreviewContainer {
  width: calc(100% - 48px);
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: $darkBlue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controlOptions {
  margin-left: 4px;
}
