@import "theme/colors";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--margin {
    margin-bottom: 32px;
  }
}

.label {
  border: 1.5px solid rgba(5, 21, 40, 0.07);
  padding: 5px 5px 5px 12px;
  border-radius: 32px;
  margin-right: 8px;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 1.1;
  display: inline-block;
  color: $grayishBlue;
  background: $white;
  white-space: nowrap;
}

.button {
  line-height: 10px;
}
