.container {
  display: flex;
  align-items: center;
}

.name {
  font-family: "Noto Serif", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-left: 15px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
