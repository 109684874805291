.popupWindow {
  width: 328px;
  height: 454px;
  margin: 0 8px;
  border: 1px solid rgba(5, 21, 40, 0.07);
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.drawerWindow {
  width: 100%;
  min-height: 100%;
}

.popupHeader {
  padding: 12px;
}

.popupFooter {
  margin: 12px;
}
