@import "theme/colors";

.card {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  padding-top: 24px;
  padding-bottom: 18px;
  margin-bottom: 20px;
  margin-right: 24px;

  &--cols3:nth-child(4n) {
    margin-right: 0;
  }

  @media screen and (max-width: 1200px) {
    &:nth-child(4n) {
      margin-right: 20px;
    }

    &:nth-child(2n) {
      margin-right: 20px;
    }
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 16px;
}

.talentName {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 12px;
  max-width: 214px;
}

.talentLocation {
  font-size: 12px;
  color: $grayishBlue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 4px;
  max-width: 214px;
}

.actionDropdown {
  position: relative;
  left: 98px;
  height: 0;
  bottom: 9px;
}
