@import "theme/colors";

.container {
  cursor: pointer;
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  font-weight: normal;
  align-items: center;
  padding: 12px 16px;
  min-width: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.option {
  width: 24px;
  height: 24px;
  border: 2px solid $grayishBlue;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-right: 12px;

  &-details {
    font-size: 12px;
  }
}

.option--selected {
  border: none $darkBlue;
  background-color: $darkBlue;
}

.label {
  -webkit-appearance: none;
}
