.button {
  width: calc(100% - 10px);
  margin: 16px 0px 0px 26px;

  &--remove {
    background: transparent;
    color: black;
  }
}

.gridContainer {
  max-height: 120px;
}
