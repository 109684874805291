@import "theme/colors";

.textarea {
  clear: both;
}

.form {
  margin: 100px;
  align-self: flex-start;
}

.radio {
  margin: 10px;
}

.checkboxContainer {
  flex-direction: column;
  flex-basis: 0;
}

.otherInput {
  margin-top: -40px;
  margin-left: 15px;
}

.radioLabel {
  color: $darkBlue;
}

.sample {
  font-size: 13px;
}
