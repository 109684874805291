@import "theme/colors";

.card {
  padding: 16px 24px;
  display: flex;
  cursor: pointer;
}

.card:hover {
  background-color: rgba($grayishBlue, 0.1);
}

.cardContent {
  width: calc(100% - 48px);
  margin-left: 16px;
}

.cardText {
  font-size: 14px;
  line-height: 20px;
  color: $darkBlue;
}

.cardTitle {
  font-weight: bold;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cardSubtitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.cardSubtitleText {
  font-weight: 500;
}

.cardTimeAgo {
  flex: 1 0 auto;
  margin-left: 6px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cardTimeAgoText {
  font-size: 12px;
  line-height: 15px;
  color: $grayishBlue;
}

.cardTimeAgoIcon {
  width: 8px;
  height: 8px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: $secondary;
}

.cardLastMessageText {
  width: 80%;
  font-size: 14px;
  line-height: 24px;
  color: $darkGrey;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.headingLabel {
  background-color: $grayishBlue;
  color: $white;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 8px;
  text-transform: uppercase;
}
