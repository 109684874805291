@import "theme/colors";

.nameContainer {
  display: flex;
  flex-direction: row;
}
.avatar {
  min-width: 32px;
  align-self: center;
}
.title {
  font-family: Inter, sans-serif;
  margin: 4px;
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  color: $darkBlue;
  max-width: 250px;
  word-wrap: break-word;
}
