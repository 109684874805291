@import "theme/colors";

.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: $darkGrey;
}

.title {
  font-weight: bold;
}
