@import "theme/colors";

.nav-bar-link.active {
  color: $white;
  position: relative;

  &::after {
    content: "";
    display: block;
    background: $secondary;
    height: 3px;
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    top: 60px;
  }
}

.nav-bar-link-no-line.active {
  color: $white;
  position: relative;
}
