@import "theme/colors";
@import "theme/breakpoints";

.container {
  width: 240px;
  display: flex;
  align-items: center;
}

.title {
  font-size: 12px;
  color: $inputPlaceholder;
}

.select {
  width: 128px;
  font-size: 12px;
  font-weight: 600;
}

.containerSelect {
  margin: 0px;
  padding-left: 7px;
}
