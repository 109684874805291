@import "theme/colors";

.btn {
  min-width: 32px;
  width: 32px;
  height: 32px !important;
  padding: 0;
  background-color: $grayishBlue !important;
  border-radius: 50% !important;
}
