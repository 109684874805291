@import "theme/colors";

.container {
  cursor: pointer;
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  font-weight: normal;
  padding: 12px 16px;
  min-width: 200px;
  word-break: break-word;

  &.disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &.with-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.menu {
    &--selected {
      font-weight: bold;
      color: $darkBlue;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  border: 2px solid $grayishBlue;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-top: 4px;

  &--selected {
    border: none $secondary;
    background-color: $secondary;
  }

  &--multiselect {
    border-radius: 2px;
  }
}

.label {
  -webkit-appearance: none;
}
