@import "theme/colors";

.indicator {
  height: 13px;
  padding: 0 4px;
  color: $white;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  text-align: center;
  background-color: $secondary;
  border-radius: 43px;
}
