@import "theme/colors";
@import "theme/breakpoints";

.banner {
  width: 100%;
  padding: 17px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: $secondary;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1128px;
}

.text {
  font-size: 18px;
  color: white;
  @media screen and (max-width: $mobileWidth) {
    font-size: 14px;
  }
}

.button {
  background: white !important;
  color: $darkBlue;
  height: 40px;
}
