@import "theme/colors";

.time {
  font-size: 24px;
  display: flex;
  margin: 0;

  > span {
    flex: 1;
    display: flex;

    span {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}

.timeText {
  color: $grayishBlue;
  display: flex;
  justify-items: stretch;
  font-size: 12px;
  line-height: 15px;
  margin: 0;

  span {
    flex: 1;
  }
}
