@import "theme/colors";

.container {
  display: flex;
  align-items: center;
  border: 1px rgba($darkBlue, 0.4) dashed;
  padding: 8px 22px;
  border-radius: 8px;
  height: 78px;
}

.circle {
  background: rgba($darkBlue, 0.05);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.button {
  min-width: 148px;
}

.text {
  flex: 1;
}

.title {
  color: $grayishBlue;
  font-size: 16px;
  line-height: 22px;
}
