@import "theme/colors";

.messagingMenu {
  display: flex;
  flex-direction: column;
}

.loadMoreButton {
  text-align: center;
  color: $secondary;
  width: 100%;
}

.seeAllButton {
  width: 100%;
  margin: 14px 0;
  text-align: center;
  color: $secondary !important;
}
