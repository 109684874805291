.container {
  margin-bottom: 44px;
}

.emptyContainer {
  margin-bottom: 0;
}

.emptyInput {
  margin: 0;
}

.fileContainer {
  &--withForm {
    padding: 1px 16px;
    background: rgba(5, 21, 40, 0.03);
  }
}

.label {
  margin-bottom: 20px;
}

.input {
  margin-bottom: 26px;
}
