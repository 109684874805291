.container {
  position: relative;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px 32px 0px 32px;
  width: 587px;
  height: 472px;
}

.cropperContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-basis: 80px;
  flex-shrink: 0;
}

.footerButtons {
  justify-self: flex-end;
  display: flex;
}

.button {
  margin-right: 10px;
}

.instructionText {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 50px);
}
