.form {
  display: block !important;
}

.avatar {
  float: left;
  padding-right: 38px;
  margin-top: 0;
}

.textarea {
  clear: both;
}

.image {
  width: 120px;
  height: 120px;
  border-radius: 60px;
}
