@import "theme/colors";

.list {
  position: absolute;
  top: 14px;
  left: 16px;
  font-size: 14px;
  line-height: 14px;

  &--disabled {
    opacity: 0.5;
    color: $inputPlaceholder;
  }
}
