@import "theme/colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.childrenContainer {
  transition: max-height 0.5s 0.2s, opacity 0.5s, margin 0.5s 0.2s;
  overflow: hidden;
  max-height: 0px;
  opacity: 0;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &--expanded {
      width: 100%;
    }
  }

  &--expanded {
    opacity: 1;
    max-height: 2000px;
    transition: max-height 0.7s, opacity 0.4s 0.5s, margin 0s;
  }
}
