@import "theme/colors";

.container {
  flex: 1;
  padding-right: 10px;
  margin-bottom: 30px;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $grayishBlue;
  margin-bottom: 5px;
}
