.label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  margin: 0 4px;
  padding: 0 6px;
}
