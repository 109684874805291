@import "theme/colors";

.input {
  font-family: "Inter", sans-serif;
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  padding: 0 16px;
  min-height: 40px;
  min-width: 10px;
  background: $inputBackground;
  color: $darkBlue;
  border-radius: 8px;
  border: 1px solid $inputBorder;
  -webkit-appearance: none;

  &::placeholder {
    color: $inputPlaceholder;
    font-size: 13px;
  }

  /* - Internet Explorer 10–11
  - Internet Explorer Mobile 10-11 */
  &:-ms-input-placeholder {
    color: $inputPlaceholder !important;
    font-size: 13px !important;
  }

  &--light {
    background: white;
  }

  &.error {
    border-color: $error;
  }

  &.link {
    font-size: 13px;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: auto !important;
  }
}

.label {
  margin-bottom: 20px;

  &.error {
    color: $error;
  }
}
