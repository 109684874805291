@import "colors.scss";
@import "material-ui.scss";

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $secondaryLight;
}

h1 {
  font-family: "Noto Serif", serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  font-style: normal;
}

h2 {
  font-family: "Noto Serif", serif;
  font-weight: 700;
  font-size: 20px;
}

h3 {
  font-family: "Noto Serif", serif;
  font-weight: 700;
  font-size: 16px;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}

.section-title {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.body16 {
  font-size: 16px;
  line-height: 28px;
}

.body14 {
  font-size: 14px;
  line-height: 20px;
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.instruction {
  font-size: 12px;
  line-height: 14px;
  color: $white;
  font-weight: 600;
  align-items: center;
  text-align: center;
}

.showCursor {
  cursor: pointer !important;
}

label,
.label {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $darkGrey;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
}

.container {
  &--1128 {
    max-width: 1128px;
  }
  &--721 {
    width: 100%;
    margin: 0 auto;
    max-width: 721px;
  }
  &--fullWidth {
    max-width: none !important;
  }
}

.divider {
  position: relative;
  border-bottom: none !important;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(5, 21, 40, 0) 5%,
      rgba(5, 21, 40, 0.07) 14.98%,
      rgba(5, 21, 40, 0.07) 46.96%,
      rgba(5, 21, 40, 0.07) 87.49%,
      rgba(5, 21, 40, 0) 95%
    );
  }
}

.page__header__content-group {
  display: flex;
  align-self: flex-end;
  padding-bottom: 23px;
}

.noScrollBar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}
