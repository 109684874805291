@import "theme/colors";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal {
    display: block !important;
    overflow: auto;
  }
}

.overlay {
  z-index: 1;
}

.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  min-height: 100%;
  padding: 0;
  background: $secondaryLight;

  > :first-child,
  > :last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.modal--windowHeight {
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.modal__header {
  background: linear-gradient(102.28deg, $darkBlue 3.24%, $midnightBlue 77.28%);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $grayishBlue;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding: 0 20px;
}

.header {
  display: flex;

  > * {
    flex: 1;
  }
}

.modal__left-container {
  width: 120px;
}

.modal__close-icon {
  width: 120px;

  &--default {
    display: none;
  }

  &--hidden {
    visibility: hidden;
  }
}

.modal__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: auto;
  align-items: center;
  max-width: 1128px;
}

.modal__content--windowHeight {
  flex: 1;
  overflow: scroll;
  margin-bottom: 110px;
  width: 100%;
}
