@import "theme/colors";

.container {
  width: 100%;
  border-radius: 8px;
  border: 1px dashed rgba(5, 21, 40, 0.3);
  box-sizing: border-box;
  padding: 16px 0;
  cursor: pointer;

  &--hasValue {
    border: none;
    height: auto;
    padding: 0;
    flex-direction: column;
  }

  &--error {
    border-color: $error;
  }

  &--noBorder {
    border: none;
    padding: 0;
  }
}

.moreSamplesBtn {
  background: transparent;
  color: $secondary;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  text-align: right;
  padding: 15px 7px;
  letter-spacing: 1px;
  float: right;
}
