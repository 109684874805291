@import "theme/colors";

.page {
  height: calc(100vh - 68px);
  background-color: $pageBackground;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.header {
  width: 100%;
  margin-bottom: 24px;
  background-color: $white;
  border: 1px solid rgba(5, 21, 40, 0.07);
  box-shadow: 0px 2px 12px rgba(5, 21, 40, 0.03);
}

.content {
  width: 1130px;
  margin: 0 auto;
}

.pageContent {
  height: calc(100% - 112px);
  display: flex;
  align-items: stretch;
}

.tabLink {
  padding: 32px 24px 24px 24px;
}
