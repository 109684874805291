@import "theme/colors";

.container {
  margin: 16px 0 36px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  &.error {
    margin-bottom: 15px;
  }
}

.label {
  margin-bottom: 20px;

  &.error {
    color: $error;
  }
}

.errorMessage {
  color: $error;
  padding-top: 6px;
  text-align: justify;
}

.inputContainer {
  display: flex;
  position: relative;
}
