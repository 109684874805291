@import "theme/colors";
@import "theme/breakpoints";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon--left {
    left: 8px;
    top: 8px;
  }
}

.input {
  font-family: "Inter", sans-serif;
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  width: 264px;
  height: 38px;
  min-height: 38px;
  min-width: 10px;
  border-radius: 8px;
  max-width: 264px;
  padding: 0 36px 0 16px;

  &--light {
    background: white;
  }

  &.error {
    border-color: $error;
  }

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &::placeholder {
    color: $inputPlaceholder;
    padding-left: 24px;
  }

  /* - Internet Explorer 10–11
  - Internet Explorer Mobile 10-11 */
  &:-ms-input-placeholder {
    color: $inputPlaceholder !important;
    padding-left: 35px !important;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.flowContainer {
  display: flex;
  position: relative;
}

.inputContainer {
  display: flex;
  margin: 0 0 0 16px;
}

.light {
  .input {
    background: none;
  }
}

.dark {
  &__input {
    background: $darkGrey;
    color: $darkBlue;
    border: 1px solid $inputBorder;

    &:focus,
    &--hasValue {
      background: white;
    }
  }
}

.icon {
  align-self: center;
  position: absolute;

  &--right {
    right: 0px;
  }

  &--left {
    left: 8px;
  }

  &--hidden {
    visibility: hidden;
  }

  &--disabled {
    opacity: 0.5;
  }
}
