@import "theme/colors";

.titleContainer {
  margin-bottom: 56px;
  text-align:center;

  .has-error & {
    margin-bottom: 21px;
  }
}

.title {
  color: $darkBlue;
}

.description {
  margin-top: 8px;
  font-weight: 300;
  color: $grayishBlue;
  white-space: pre-line;
}
