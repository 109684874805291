@import "theme/colors";

.story {
  margin: 30px 20px;
  border-radius: 8px;
  border: 2px dotted rgba(86, 99, 113, 0.1);
}

.story--small {
  width: 600px;
}

.title {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgba(86, 99, 113, 0.1);
  padding: 5px 10px 10px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  color: $darkGrey;
  font-size: 9px;
  text-transform: uppercase;
}

.content {
  padding: 20px;

  .dark & {
    background-color: $grayishBlue;
  }
}
