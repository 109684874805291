@import "theme/colors";

.sectionTitle {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $grayishBlue;
  margin-bottom: 5px;
}

.sectionContent {
  word-break: break-word;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
