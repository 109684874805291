@import "theme/colors";
@import "theme/breakpoints";

.container {
  display: flex;
  flex-direction: row;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.coverImage {
  width: 120px;
  height: 120px;
  margin-right: 25px;
}

.coverImageAlt {
  margin-right: 25px;
}

.title {
  max-width: 43vw;
  overflow: hidden;
  word-wrap: break-word;
  margin: 0px 0px 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @media screen and (max-width: $tabletWidth) {
    line-height: normal;
    font-size: 24px;
    max-width: 70vw;
    padding: 0px;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
