.message {
  max-width: 100%;
  display: block;
  padding: 10px;
  margin-bottom: 2px;
  mix-blend-mode: normal;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
