.container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 356px;
  border-radius: 8px;
  margin-left: 60px;
}

.progressBar {
  width: 56px;
  display: inline-block;
}

.headingContainer {
  display: flex;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.heading {
  align-items: center;
  width: 126px;
  align-self: flex-start;
  margin: 0px 5px;
  margin-left: 10px;
}

.text {
  font-family: Inter;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.rows {
  width: 223px;
  height: 240px;
}

.row {
  margin-top: 15px;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 12px;
}

.bold {
  font-weight: 600;
}

.loader {
  margin: 100px;
  height: 100vh;
}
