@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .container {
    height: 0;
  }
}

.container {
  flex: 1;
  min-height: 200px;
}
