@import "theme/colors";

.frame {
  width: 100%;
  background-color: $white;
  border: 1px solid rgba(5, 21, 40, 0.07);
  box-shadow: 0px 2px 12px rgba(5, 21, 40, 0.03);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
}
