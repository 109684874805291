.container {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  &.vertical {
    flex-direction: column;
  }
}

.container--small {
  width: 500px;
}
