@import "theme/colors";

.card {
  border: 1px solid rgba(5, 21, 40, 0.07);
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 24px;
  min-width: 200px;
  background: #ffffff;
  box-shadow: 0 2px 12px rgba(5, 21, 40, 0.03);
}
