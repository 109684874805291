@import "theme/colors.scss";

.card {
  margin: 0 0 12px;
  width: 100%;
}

.containerClassName {
  margin-bottom: 80px;
}

.header {
  font-size: 14px;
  font-weight: 500;
}

.hiredButton {
  width: 132px;
  height: 32px;
  min-width: 132px;
}

.hiredIcon {
  margin-right: 10px;
}

.hiredLabel {
  color: $grayishBlue;
}

.smallCell {
  width: 5px;
}

.mediumCell {
  width: 150px;
  padding-right: 16px;
}

.largeCell {
  width: 200px;
}

.nameCell {
  width: 25%;
}

.sampleCell {
  width: 30%;
}

.noteCell {
  width: 25%;
  font-size: 14px;
  word-break: break-word;
}
