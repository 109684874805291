@import "theme/colors";
@import "theme/breakpoints";

.card {
  border: 1px solid rgba(5, 21, 40, 0.07);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 18px 24px;
  height: 316px;
  min-width: 280px;
  width: 264px;
  flex-direction: column;
  align-self: center;
  display: flex;
  cursor: pointer;
  background: white;
  margin-right: 8px;
  margin-bottom: 24px;
  @media screen and (min-width: $desktopWidth) {
    margin-right: 0px;
    margin-bottom: 0px;
    min-width: 264px;
    width: 264px;
  }
  @media screen and (max-width: $flippedMobileWidth) {
    min-width: 300px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.name {
  margin-bottom: 4px;
  margin-top: 4px;
  max-width: 216px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 14px;
  color: $darkBlue;
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 72px;
  line-height: 24px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
}

.section {
  flex: 1;

  &:first-child {
    flex: 2;
  }
}

.detailsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
}

.coverImageAlt {
  margin-right: 6px;
  align-self: center;
  justify-self: center;
}

.coverImage {
  height: 40px;
  width: 40px;
  border-radius: 4.4px;
  margin-right: 6px;
  align-self: center;
}

.metadata {
  color: $darkGrey;
  font-size: 14px;
}

.vertical {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: white;
  background-color: $secondary;
  border-radius: 20px;
  padding: 0px 6px;
  width: fit-content;
  height: 15px;
}

.footer {
  flex: 1;
  display: flex;
  border-top: 1px solid rgba(5, 21, 40, 0.07);
  padding-top: 28px;
  padding-bottom: 2px;
}

.avatarName {
  font-size: 12px;
  line-height: 15px;
  color: $darkBlue;
  white-space: nowrap;
  overflow: hidden;
  max-width: 175px;
  text-overflow: ellipsis;
}

.avatarDescriptionSubContainer {
  padding-bottom: 3px;
}
