@import "theme/colors";
@import "theme/breakpoints";

.contentContainer {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1128px;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  margin-top: -24px;
  min-width: 85vw;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.coverImageAlt {
  margin-right: 25px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.title {
  max-width: 43vw;
  overflow: hidden;
  word-wrap: break-word;
  margin: 0px 0px 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @media screen and (max-width: $tabletWidth) {
    margin: 0;
    line-height: normal;
    font-size: 24px;
    max-width: 70vw;
    padding: 0px;
  }
}

.actionContainer {
  display: flex;
  margin-top: 26px;
}

.submitButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
}

.imageWithTitleContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
}

.saveButton {
  align-self: center;
  padding-top: 7px;
  background-color: $darkGrey;
  margin-left: 16px;
  min-width: 40px;
  @media screen and (max-width: $tabletWidth) {
    flex: 1;
    background-color: $secondary;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-left: 13px;
    width: 20px;
  }
}

.savedButton {
  align-self: center;
  padding-top: 7px;
  background-color: rgba(255, 255, 255, 0.1);
  color: $inputPlaceholder;
  margin-left: 16px;
  min-width: 40px;
  @media screen and (max-width: $tabletWidth) {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 0;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverImage {
  width: 120px;
  height: 120px;
  margin-right: 25px;
  @media screen and (max-width: $tabletWidth) {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
}

.savedButtonLabel,
.messageButtonLabel {
  padding-left: 10px;
}

.sendMessageButton {
  min-width: 124px !important;
  width: 124px;
  margin-left: 16px;
  display: flex;
  @media screen and (max-width: $tabletWidth) {
    flex: 1;
    margin: 0;
  }
}

.appliedButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  color: $inputPlaceholder;
}
