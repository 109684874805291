@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon.icon--right {
    top: 8px;
  }
}

.icon {
  align-self: center;
  position: absolute;

  &.icon--right {
    right: 12px;
  }

  &.icon--left {
    left: 12px;
  }
}
