.emptyPlaceholderContainer {
  border: none;
}

.viewAllContainer {
  display: flex;
  flex-direction: column;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
