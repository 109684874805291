@import "theme/colors";

.label {
  margin-bottom: 4px !important;

  &.error {
    color: $error;
  }
}

.container {
  height: 76px;
}

.error {
  height: 97px !important;
}
