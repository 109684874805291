@import "theme/colors";

.container {
  display: flex;
  margin-bottom: 20px;

  *:first-child {
    flex-shrink: 0;
  }
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.company {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}

.title {
  font-family: Inter, sans-serif;
  margin: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  color: $darkBlue;
  max-width: 250px;
  word-wrap: break-word;
}

.verticals {
  width: 250px;
}

.avatar {
  margin-bottom: 5px;
  align-self: center;
}
