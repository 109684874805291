@import "theme/colors";

.editButton {
  border: 1px solid black;
  cursor: pointer;
  padding: 0;
  background: #051528;
  position: relative;
  overflow: hidden;

  // round shape
  width: 121px;
  height: 121px;
  border-radius: 60px;

  &:focus {
    outline: none;
  }

  &:hover {
    .tintedBackground {
      display: flex;
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

.instructionText {
  position: relative;
}

.tintedBackground {
  display: none;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.image {
  position: absolute;
  top: 0;
  left: 0;

  // round shape
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

.removeButton {
  margin-top: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808b99;
  border: none;
  width: 100%;
  padding: 12px 30px;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

.label {
  margin-bottom: 20px;
}
