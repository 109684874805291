@import "theme/colors";

.content {
  overflow-y: auto;
  margin-bottom: 64px;
  max-height: 90%;
}

.sampleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 22px;
}

.sampleNumber {
  color: $grayishBlue;
  font-size: 16px;
  margin-bottom: 24px;
}

.lineDivider {
  margin-bottom: 24px;
}
