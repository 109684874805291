@import "theme/colors";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn {
    min-width: 30px;
  }
}

.btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: inline-block;

  &:focus {
    outline: none;
  }

  &.disabled {
    cursor: default;
  }
}

.activeIndicator {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: $secondary;
  margin-bottom: 20%;
}
