@import "theme/breakpoints";

.dropdownMenu {
  height: calc(100% - 52px); //Mobile drawer container minus the header
  @media screen and(min-width: $tabletWidth + 1px) {
    height: 600px
  }
}

.dropdownPlaceholder {
  width: 70%;
  margin: 80px auto;
}
