@import "theme/colors";

.tabLink {
  display: block;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: $grayishBlue;
  position: relative;
  margin-bottom: 43px;
  padding: 5px 16px;
  cursor: pointer;

  &--active {
    color: $darkBlue;
    font-weight: 700;
    margin-left: 16px;

    &:before {
      content: "";
      display: block;
      background: $secondary;
      height: 32px;
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
}
