@import "theme/colors";

.header {
  font-size: 14px !important;
  font-weight: 500;
  color: $grayishBlue !important;
}

.clientCell {
  width: 220px;
}

.largeCell {
  width: 135px;
}

.mediumCell {
  width: 125px;
}

.nameCell {
  cursor: pointer;
}

.offerStatusCell {
  width: 70px;
  padding-left: 45px !important;
}
