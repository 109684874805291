@import "theme/colors";

.container {
  position: relative;
  background: white;
  border-radius: 8px;
  width: 552px;
  min-height: 300px;
  padding: 10px 32px 26px 32px;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: $darkBlue;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .footer {
    flex-basis: auto !important;
  }
}

.footer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.button {
  margin-left: 16px;
  padding-right: 23px;
  padding-left: 23px;
  min-width: 128px;
}
.children {
  min-height: 115px;
  height: 100%;
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.subtitle {
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
  margin-bottom: 38px;
  text-align: left;
}

.error {
  font-size: 14px;
  line-height: 24px;
  color: $error;
}
