@import "theme/colors.scss";
@import "theme/breakpoints.scss";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .container {
    min-height: 152px;

    &--large {
      min-height: 230px;
    }
  }

  .content_container {
    width: 100%;
  }
}

.container {
  background: linear-gradient(103.5deg, #051528 3.24%, #1c2a3b 77.28%);
  color: $white;
  min-height: 196px;
  display: flex;
  min-width: 100%;
  &--large {
    height: 230px;
    box-sizing: border-box;
  }
  @media screen and (max-width: $mobileWidth) {
    background-attachment: fixed;
  }
}

.content_container {
  max-width: 1128px;
  margin: 0 auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $desktopWidth) {
    max-width: 90%;
  }
  @media screen and (max-width: $tabletWidth) {
    max-width: 700px;
  }
  @media screen and (max-width: $mobileWidth) {
    max-width: 100%;
  }
}

.breadcrumbs {
  width: 100%;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $tabletWidth) {
    justify-content: center;
  }
}

.header-button {
  align-self: center;
  width: 170px;
  margin-left: 20px;
  font-weight: 500;
}

.actions {
  display: flex;
  margin-bottom: 26px;
}

.input {
  width: 264px;
  height: 40px;
}

.header-search {
  display: flex;
}
