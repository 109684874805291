.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fileContainer {
  box-shadow: 0 2px 8px rgba(5, 21, 40, 0.05);
  border-radius: 8px;
  background: white;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialogSubtitle {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
}
