@import "theme/colors";

.container {
  height: 200px !important;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1128px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.coverImage {
  height: 120px;
  width: 120px;
  border-radius: 6px;
}

.title {
  margin-left: 24px;
}

.submitButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
}

.finishedButton {
  align-self: center;
  width: 170px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  color: $inputPlaceholder;
}

.actions {
  margin-bottom: 0px !important;
}
