@import "helpers.scss";

$softBlue: #4991e5;
$darkBlue: #051528;
$darkBlue05: rgba-to-rgb(rgba($darkBlue, 0.05), #fff);
$midnightBlue: #1c2a3b;
$white: #ffffff;
$warning: #f4c5b7;
$grayishBlue: #808b99;
$darkGrey: #566371;
$darkGrey05: rgba-to-rgb(rgba($darkGrey, 0.05));
$error: #d93025;
$inputBackground: rgba($darkBlue, 0.05);
$inputBorder: rgba($darkBlue, 0.07);
$inputPlaceholder: #808b99;
$green: #63c173;
$secondary: #da3d0c;
$secondaryLight: linear-gradient(
    0deg,
    rgba(240, 242, 120, 0.05),
    rgba(240, 242, 120, 0.05)
  ),
  #ffffff;
$auditionAppliedTag: rgba(6, 22, 41, 0.5);
$tableHover: #f2f3f4;
$navigationTabLinkHover: #f2f3f4;
$link: #da3d0c;
$pageBackground: #fbfbfb;

.auditionAppliedTagColor {
  color: $auditionAppliedTag;
}

.grayishBlueColor {
  color: $grayishBlue;
}

.darkGreyColor {
  color: $darkGrey;
}

.lightGrayColor {
  color: $inputPlaceholder;
}

.darkBlueColor {
  color: $darkBlue;
}

.secondaryColor {
  color: $secondary;
}

.warningColor {
  color: $warning;
}

.errorColor {
  color: $error;
}
