.shareButton {
  align-self: center;
  min-width: 40px;
  height: 40px;
  margin-left: 16px;
  background-color: rgb(86, 99, 113);
}

.shareIcon {
  margin-bottom: 2px;
}
