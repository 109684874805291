@import "theme/colors";

.heading {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
}

.headingText {
  flex: 1;
  margin: 0 16px;
}

.headingTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  margin: 0 6px 0 0;
}

.headingSubtitle {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
