@import "theme/colors";

.btn {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  height: 40px;
  border: none;
  cursor: pointer;
  min-width: 168px;
  justify-content: center;
  align-items: center;
  background: none;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    border-style: none;
    opacity: 0.7;
  }

  &--small {
    min-width: auto;
    height: auto;
  }

  &--primary {
    background: $secondary;
    color: $white;
    height: 40px;
    padding: 1px 10px 2px;
  }

  &--secondary {
    color: $darkBlue;
    background: $darkBlue05;
  }

  &--header {
    color: white;
    background: $darkGrey !important;
  }

  &--light {
    color: $darkBlue;
    border: 1px solid rgba(5, 21, 40, 0.07);
    text-transform: none;
    box-sizing: border-box;
  }

  &--gray {
    background-color: $darkGrey;
    color: $white;
  }

  &--dark {
    color: $white;
    border: 1px solid rgba(255, 255, 255, 0.07);
    text-transform: none;
    box-sizing: border-box;
  }

  &--disabled {
    opacity: 0.5 !important;
    cursor: default;

    &:hover {
      opacity: 0.5;
    }
  }

  &.btnLink {
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 1px;
  }

  .loader,
  .loader div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
