@import "theme/colors";

.dark {
  background: $darkGrey;
  color: $white;
}

.sampleContainer {
  width: 225px;
  margin-top: 12px;
}

.sampleText {
  color: $grayishBlue;
  margin-right: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.sampleTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sampleDurationLabel {
  color: $white !important;
}

.timestampText {
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  color: $grayishBlue;
  text-align: right;
}
