@import "theme/colors";

.link {
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.04em;
  color: $grayishBlue;
  position: relative;
  margin-bottom: 10px;
  margin-right: 36px;
  display: inline-block;
  padding: 5px 0;
  cursor: pointer;

  &--active {
    color: $darkBlue;
    font-weight: 700;
    margin-left: 0px;

    &--light {
      color: white;
    }

    &:after {
      content: "";
      display: block;
      background: $secondary;
      height: 3px;
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      border-top-right-radius: 100px;
      border-top-left-radius: 100px;
    }
  }

  &--disabled {
    opacity: 0.5;
  }
}
