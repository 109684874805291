.container {
  display: flex;
  flex-direction: column;
  height: 111px;
  padding-top: 0;
  padding-bottom: 0;
}

.dateInfo {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
}

.name {
  font-family: "Noto Serif", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: none;
  margin-left: -17px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
}

.info {
  margin-right: 30px;
  min-width: 70px;
  display: inline-block;
  min-height: 30px;
}

.submissions {
  min-width: 93px;
  min-height: 30px;
}

.actionButton {
  height: 32px;
  background: none;
  color: black;
  margin-right: 35px;
}

.nameContainer {
  width: 300px;
  margin: 0;
}
