@import "theme/colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .expandedChildren {
    width: 100%;
  }
}

.expandedChildren {
  margin-bottom: 20px;
}

.toggleButton {
  background: transparent;
  color: $secondary;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  padding: 15px 7px;
  letter-spacing: 1px;
}
