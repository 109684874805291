@import "theme/colors";

.bar {
  width: 21px;
  height: 4px;
  margin: 2px;
  border-radius: 4px;
  background-color: $grayishBlue;

  &--active {
    background-color: $secondary;
  }
}
