@import "theme/colors";

.drawerHeader {
  background: linear-gradient(103.5deg, #051528 3.24%, #1c2a3b 77.28%);
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  position: sticky;
  top: 0%;
  width: 100%;
  z-index: 2;
}
.backButton {
  width: 15%;
  padding-left: 6px;
  margin-right: -6px;
  align-self: center;
}
.rightComponent {
  width: 15%;
  align-self: center;
}
