@import "theme/colors";
@import "theme/breakpoints";

.actionContainer {
  display: flex;
}

.actionButton {
  min-width: 160px;
  @media screen and(max-width:$smallMobileWidth) {
    font-size: 12px;
    min-width: 110px;
  }
  &--light {
    align-self: center;
    font-weight: 500;
    background-color: $darkGrey;
    margin-right: 20px;
    color: white;
  }
}

.negotiatingButton {
  width: 250px;
  white-space: nowrap;
  @media screen and(max-width:$mobileWidth) {
    white-space: pre-wrap;
    width: 180px;
  }
  @media screen and(max-width:$smallMobileWidth) {
    font-size: 12px;
    width: 110px;
    line-height: 16px;
  }
  &--light {
    align-self: center;
    font-weight: 500;
    background-color: $darkGrey;
    margin-right: 20px;
    color: white;
  }
}
