@import "theme/colors";
@import "theme/breakpoints";

.pageContainer {
  background-image: url(../components/AuthPage/assets/ChooseSignupPageBackground.jpg);
  background-position: center;
  background-size: cover;
}

.layer {
  background-color: rgba(5, 21, 40, 0.9);
  position: relative;
  min-height: 100vh;
  display: flex;
  flex: 1;
}

.container {
  text-align: center;
  align-self: center;
  padding-bottom: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: $mobileWidth) {
    margin-bottom: 20px;
  }
}

.cardsContainer {
  display: flex;
  @media screen and (max-width: $mobileWidth) {
    flex-direction: column;
  }
}

.title {
  color: white;
  font-size: 32px;
}

.subtitle {
  color: $grayishBlue;
  padding-bottom: 60px;
  font-size: 16px;
}
