@import "theme/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: "Noto Serif", serif;
  font-weight: bold;
  font-size: 16px;
  white-space: normal;
  word-break: break-word;

  &--simple {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.playContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.isPlayingContainer {
  display: flex;
  flex: 1;
  border-radius: 39px;
}

.playerBackground {
  background: rgba(184, 197, 211, 0.2);
}

.details {
  color: $grayishBlue;
  font-size: 14px;
  line-height: 24px;

  &--simple {
    font-size: 12px;
    line-height: 15px;
  }
}

.information {
  margin-left: 12px;
}

.detail {
  + .detail:before {
    content: ",";
    margin-right: 5px;
    display: inline-block;
    position: relative;
  }

  &--dot {
    + .detail:before {
      content: " ‧ ";
      margin-left: 5px;
    }
  }
}

.sliderContainer {
  padding-left: 12px;
}

.titlePlayerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - 32px);
}

.showTitleContainer {
  margin-left: 24px;
}
