@import "theme/colors";
@import "theme/breakpoints";

.container {
  position: relative;
  background: white;
  border-radius: 8px;
  width: 410px;
  height: auto;
  box-sizing: border-box;
  padding: 10px 32px 26px 32px;
  word-break: break-word;
  @media screen and (max-width: $mobileWidth) {
    width: 360px;
  }
}

.title {
  margin-right: 30px;
  color: $darkBlue !important;
  text-align: left;
  white-space: normal;
}

.text {
  line-height: 28px;
  color: $grayishBlue;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
}

.button {
  flex: 1;
  flex-grow: 0;
  flex-basis: 161px;
  @media screen and (max-width: $mobileWidth) {
    flex-basis: 140px;
    min-width: 140px;
  }
}

.closeButtonContainer {
  position: absolute;
  right: 24px;
  top: 22px;
}

.subtitle {
  font-size: 14px;
  line-height: 24px;
  color: $grayishBlue;
  margin-bottom: 38px;
  text-align: left;
}
