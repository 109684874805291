@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tabLayout {
    display: -ms-grid;
  }

  .tabMenu {
    -ms-grid-column: 1;
  }

  .tabContent {
    min-width: 552px;
    margin-left: 150px;
    -ms-grid-column: 2;
  }
}

.container {
  width: 100%;
}

.tabLayout {
  width: 100%;
  display: grid;
  -ms-grid-columns: 1fr 552px 1fr;
  grid-template-columns: 1fr 552px 1fr;
  grid-template-areas: "tabsMenu tabsContent requirementsContent";
}

.tabMenu {
  grid-area: tabsMenu;
  margin-top: 222px;
}

.tabContent {
  grid-area: tabsContent;
  margin-top: 92px;
  padding-bottom: 128px;
}

.requirementContent {
  grid-area: requirementsContent;
  margin-top: 222px;
}
