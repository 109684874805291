@import "theme/colors";

.sendMessageSection {
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  position: relative;
}

.textareaContainer {
  margin: 0 !important;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  flex-basis: calc(100% - 60px) !important;
}

.textareaFileSelectedContainer {
  height: 112px;
}

.textareaNoFileSelectedContainer {
  height: 48px;
}

.textarea {
  margin: 0;
  padding: 12px 10px;
  flex: 1;
}

.textareaNoFileSelected {
  padding: 12px 42px 12px 10px;
}

.textareaFileSelected {
  padding: 60px 10px 12px 10px;
}

.sendButton {
  flex: 0 0 48px;
  min-width: 0;
  height: 48px;
  padding: 0;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadSampleButtonContainer {
  position: absolute;
}

.uploadSampleButtonContainerNoFileSelected {
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
}

.uploadSampleButtonContainerFileSelected {
  width: calc(100% - 56px);
  top: 6px;
  left: 6px;
}

.uploadSampleButton {
  width: 36px;
  height: 36px;
  min-width: 0;
  background: rgba(5, 21, 40, 0.05);
  mix-blend-mode: normal;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sampleFileContainer {
  background: $white;
  border: 1px solid rgba(5, 21, 40, 0.07);
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(5, 21, 40, 0.05);
  border-radius: 8px;
}
