@import "theme/colors";

.tabLink {
  display: inline-block;
  width: 150px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.04em;
  color: $grayishBlue;
  position: relative;
  margin: 16px 60px 13px 5px;
  padding: 5px 16px;

  &:hover {
    background: $navigationTabLinkHover;
    border-radius: 2px;
  }

  &--active {
    color: $darkBlue;
    font-weight: 700;

    &:hover {
      background: none;
    }

    &:before {
      content: "";
      display: block;
      background: $secondary;
      height: 32px;
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }

  &--disabled {
    opacity: 0.5;
  }
}
