@import "theme/colors";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rightContainer {
    display: initial !important;
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
}

.overviewTitle {
  font-size: 16px;
  font-weight: 600;
}

.overviewContainer {
  display: flex;
  justify-content: space-between;
}

.recordingDate {
  font-size: 24px;
  font-family: "Noto Serif", serif;
  font-weight: 700;
  padding-bottom: 10px;
}

.recordingYear {
  font-size: 12px;
  color: $inputPlaceholder;
}
