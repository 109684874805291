@import "theme/colors";

.arrow {
  position: absolute;
  top: 17px;
  right: 20px;
}

.option-remove {
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  font-weight: normal;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 200px;
  cursor: pointer;
}

.container {
  &--tag {
    margin-bottom: 15px;
  }
}

.select-input {
  text-overflow: ellipsis;
  padding-right: 35px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .select-input::-ms-clear {
    display: none;
  }
}
.tagListContainer {
  max-width: 232px;
  word-break: break-word;
}
