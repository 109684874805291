.icon {
  margin-right: 10px;
  fill: none;
}

.message {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-right: 16px;
  margin-left: 16px;
}
