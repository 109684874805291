@import "theme/colors";

.container {
  display: flex;
  margin-bottom: 20px;

  *:first-child {
    flex-shrink: 0;
  }
}

.title {
  font-family: Inter, sans-serif;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  color: $darkBlue;
  max-width: 250px;
  word-wrap: break-word;
}

.link {
  color: $darkBlue;
  text-decoration: none;
}

.icon {
  margin-right: 12px;
  margin-top: 4px;
}
