@import "theme/breakpoints";

.paperContent {
  width: 100%;
  height: 100%;
  @media screen and (min-width: $mobileWidth) {
    max-width: $mobileWidth;
  }
}
.content {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow-x: hidden;
}
