@import "theme/colors";

.light {
  background: rgba(5, 21, 40, 0.03);
  color: $darkGrey;
}

.sampleContainer {
  width: 225px;
  margin-top: 12px;
}

.sampleText {
  color: $grayishBlue;
  margin-right: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.sampleDurationLabel {
  color: $grayishBlue;
}

.timestampText {
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  color: $grayishBlue;
}
