@import "theme/colors";

.textButton {
  cursor: pointer;
  padding: 5px;
  color: $softBlue;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    opacity: 0.8;
  }
}

.plainTextButton {
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: $darkGrey;
  text-decoration: underline;
  cursor: pointer;
  padding: 5px;
  letter-spacing: 1px;

  &:hover {
    opacity: 0.8;
  }
}

.inlineWithText {
  font-size: 16px;
  padding: 0px 5px 0px;
  letter-spacing: 0px;
}
