@import "theme/colors";

.container {
  cursor: pointer;
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  font-weight: normal;
  align-items: center;
  padding: 12px 16px;
  min-width: 200px;
  word-break: break-word;
  justify-content: space-between;

  &.disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &.with-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.menu {
    &--selected {
      font-weight: bold;
      color: $darkBlue;
    }
  }
}

.icon {
  min-width: 24px;
  height: 24px;
  border: 2px solid $grayishBlue;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-right: 12px;

  &--selected {
    border: none $darkBlue;
    background-color: $darkBlue;
  }

  &--multiselect {
    border-radius: 2px;
  }
}

.labelContainer {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 10;
  margin-left: 16px;
}

.label {
  -webkit-appearance: none;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: $darkBlue;
}

.locationLabel {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: $grayishBlue;
}
.avatar {
  min-width: 40px;
}
