@import "theme/colors";
@import "theme/breakpoints";

.card {
  margin: 12px;
  position: relative;
  @media screen and (max-width: $tabletWidth) {
    margin-bottom: -12px;
  }
}

.cardContainer {
  padding: 21px 21px;
}

.headerContainer {
  padding-bottom: 11px;
}

.header {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 35px;
  padding-bottom: 10px;
  margin-top: -9px;
  border-bottom: 1px solid rgba(5, 21, 40, 0.07);
}

.headerTitle {
  padding-left: 2px;
  color: $darkGrey;
}
