@import "theme/colors";

.avatarMenu {
  min-width: 70px;
  height: 42px;
  background-color: rgba(196, 196, 196, 0.2);
  border-radius: 42px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
