.container {
  margin-top: 36px;
}

.title {
  font-family: "Inter";
  font-weight: 600;
  margin: 24px 0;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
