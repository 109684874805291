@import "theme/colors";

.card {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  margin: 12px;
}

.link {
  text-decoration: none;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0px;
  color: black;
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  line-height: 24px;
  padding: 22px 0 26px;
}

.detailsContent {
  display: flex;
  flex-direction: row;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date {
  line-height: normal;
  color: $grayishBlue;
}

.coverImage {
  height: 64px;
  width: 64px;
  border-radius: 4.4px;
  margin-right: 16px;
  align-self: center;
}

.coverImageAlt {
  margin-right: 16px;
  align-self: center;
}

.vertical {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: white;
  background-color: $secondary;
  border-radius: 20px;
  padding: 0px 6px;
  width: fit-content;
  height: 15px;
}

.separator {
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(5, 21, 40, 0) 0.05%,
    rgba(5, 21, 40, 0.07) 14.98%,
    rgba(5, 21, 40, 0.07) 46.96%,
    rgba(5, 21, 40, 0.07) 87.49%,
    rgba(5, 21, 40, 0) 99.95%
  );
}

.footer {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(5, 21, 40, 0.05%);
  padding-top: 20px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $darkBlue;
}

.bullet {
  color: $grayishBlue;
  margin: 0px 12px;
}
