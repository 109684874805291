@import "theme/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  font-family: "Noto Serif", serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
}
