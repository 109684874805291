@import "theme/breakpoints";

.button {
  border: none;
  display: flex;
  background: none;
  color: white;
  padding: 0px;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
