@import "theme/colors";
@import "theme/breakpoints";

.btnContainer {
  min-width: 170px;
}

.btn {
  min-width: 100%;
}

.cancelBtn {
  align-self: flex-end;
  min-width: 170px;
  margin-right: 24px;
}

.backBtn {
  align-self: flex-end;
  min-width: 170px;
  margin-right: 24px;
}

.footer {
  margin-top: 40px;
  @media screen and (max-width: $mobileWidth) {
    margin-top: 0px;
  }
}

.container {
  display: flex;
  justify-content: flex-end;
}

.description {
  max-width: 286px;
  margin-right: 30px;
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 16px;
  color: $grayishBlue;
}

.dividerContainer {
  padding-bottom: 11px;
}

.divider {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(5, 21, 40, 0) 0.05%,
    rgba(5, 21, 40, 0.07) 14.98%,
    rgba(5, 21, 40, 0.07) 46.96%,
    rgba(5, 21, 40, 0.07) 87.49%,
    rgba(5, 21, 40, 0) 99.95%
  );
}
