@import "theme/colors";

.buttonContainer {
  display: inline-block;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  display: inline-block;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  color: $grayishBlue;
  margin-left: 12px;
}

.title {
  font-size: 14px;
  line-height: 18px;
}

.description {
  font-size: 10px;
  line-height: 12px;
}
