.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 6px;
}

.closeSVG {
  width: 20px;
  height: 20px;
}
