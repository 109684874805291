@import "theme/colors.scss";
@import "theme/breakpoints.scss";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .page {
    flex: 0 1 auto !important;
    min-height: 100vh;
  }
}

.page {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background: $secondaryLight;
}

.page--notFound {
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
}

.page--error500 {
  align-items: center;
  flex-direction: column;
}

.page__content {
  width: 100%;
  display: flex;
  flex: 1;
  flex-basis: auto;
  justify-content: center;
  margin: auto;
  max-width: 1128px;
  padding-bottom: 100px;
  @media screen and (max-width: $desktopWidth) {
    max-width: 90%;
  }
}
