@import "theme/colors";
@import "theme/breakpoints";

.bannerText {
  margin: 0 auto;
  @media screen and (max-width: $tabletWidth) {
    font-size: 12px;
    line-height: 16px;
    margin: 0 12px;
    text-align: center;
  }
}

.modalFormHeader {
  text-align: left;
  margin-top: 24px;
}

.modalFormFooter {
  height: 80px;
  margin-top: 12px;
}
