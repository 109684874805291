@import "theme/colors";
@import "theme/breakpoints";

.form {
  flex-basis: 520px;
  flex-grow: 0;

  @media screen and (max-width: $mobileWidth) {
    flex-basis: 300px;
    max-width: 90%;
  }
}

.error {
  background-color: rgba(217, 48, 37, 0.1);
  padding: 20px 19px 18px 19px;
  border-radius: 8px;
  margin-bottom: 23px;
  color: $error;
  font-size: 12px;
}
