$left-bar-visible-width: 1000;

@media all and (min-width: $left-bar-visible-width*1px) {
  .authPage {
    display: flex;
    height: 100vh;
  }

  .quotation {
    margin: 100px 32px;
  }

  .sideBar {
    flex: 0 0 420px;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(
        0deg,
        rgba(5, 21, 40, 0.9),
        rgba(5, 21, 40, 0.9)
      ),
      url("./assets/Background.jpg");
    background-position: center;
    background-size: cover;
    opacity: 0.9;
  }

  .authHeader {
    width: 100%;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
  }
}

.content {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 50px;
  overflow: auto;
}

:export {
  leftBarVisibleWidth: $left-bar-visible-width;
}
