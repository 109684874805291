@import "theme/colors";

.container {
  display: flex;
}

.name {
  max-width: 40vw;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
}

.info-container {
  flex: 1;
  justify-self: flex-start;
  margin: 0 24px;
}

.text {
  max-width: 40vw;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $grayishBlue;
}
