@import "theme/colors";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .banner {
    min-height: 56px;
  }
}

.banner {
  width: 100%;
  height: 56px;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  background: #f4f4f4;
}

.text {
  color: $darkGrey;
}
