.actionButton {
  height: 32px;
  width: 156px;
  text-align: left;
  padding-left: 18px;

  &--secondary {
    background: none;
    color: black;
    margin-right: 20px;
  }
}

.shareIcon {
  margin-right: 5px;
}
