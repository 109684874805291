@import "theme/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
}
.durationLabel {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: $grayishBlue;
  margin-right: 12px;
  margin-left: 12px;
  min-width: 34px;
  max-width: 34px;
}
