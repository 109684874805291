@import "theme/colors";

.MuiListItem-divider {
  border-bottom: none !important;

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(5, 21, 40, 0) 0.05%,
      rgba(5, 21, 40, 0.07) 14.98%,
      rgba(5, 21, 40, 0.07) 46.96%,
      rgba(5, 21, 40, 0.07) 87.49%,
      rgba(5, 21, 40, 0) 99.95%
    );
  }
}

.MuiTextField-root {
  width: 550px;
  padding: 0 !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  border: none !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background: transparent !important;
}

.MuiAutocomplete-option:hover {
  background: $tableHover !important;
}

.MuiTextField-root > .Mui-error {
  border: 1px solid $error;
}

.Mui-error {
  color: $error !important;
}

.MuiOutlinedInput {
  &-root {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  &-input::placeholder {
    font-size: 13px;
    font-family: "Inter", sans-serif !important;
  }
}

.MuiFormLabel-root {
  margin-bottom: 18px;
}

.MuiMenuItem-root {
  border-bottom: none !important;

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(5, 21, 40, 0) 0.05%,
      rgba(5, 21, 40, 0.07) 14.98%,
      rgba(5, 21, 40, 0.07) 46.96%,
      rgba(5, 21, 40, 0.07) 87.49%,
      rgba(5, 21, 40, 0) 99.95%
    );
  }
}

[role="tooltip"] {
  z-index: 3;
  animation: animateShowElement 0.1s;
  animation-fill-mode: forwards;
}

// Fixes the twitch that happens because of hidden portal
// on dropdown elements (in 95% of cases)
@keyframes animateShowElement {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
