@import "theme/colors";

.titleContainer {
  display: flex;
  flex-direction: column;
}

.subtitleContainer {
  cursor: default;
}
