@import "theme/colors";

.container {
  display: flex;
}

.trashButton {
  width: 40px;
  padding: 6px 10px;
  border-radius: 8px;
  margin-right: 12px;
  margin-left: 12px;
  background: rgba(5, 21, 40, 0.05) !important;
  display: inline-block;
}

.replaceButton {
  min-width: 111px !important;
  background: rgba(5, 21, 40, 0.05);
  color: $darkBlue;
  display: inline-block;
}
