.fieldButton {
  cursor: pointer;
  padding: 0 17px;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: space-between;
}

.description {
  font-weight: normal;
  white-space: pre-wrap;
}

.button {
  min-width: 168px;
}

.loader {
  justify-content: center;
  display: flex;
  flex: 1;
}
