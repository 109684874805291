@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .chooseVerticalContainer {
    width: 200px;
  }
}

.chooseVerticalContainer {
  display: inline-block;
  margin-bottom: 20px;
}

.samplesPreviewCard {
  padding: 0;
  background: transparent;
  box-shadow: none;
  border: none;
}

.placeholderContainer {
  border-bottom: none;
}
