@import "theme/colors";
@import "theme/breakpoints";

$offerCardWidth: 300px;

.offersPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.container {
  max-height: 50px;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: 500;
  color: $grayishBlue;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: $tabletWidth) {
    width: calc(
      100% - ((100% - #{$offerCardWidth} * 2) / 4)
    ); //Container width has to be consistant with Cards container because of the filters allignment
    margin: 15px 8px 32px;
  }
  @media screen and (max-width: $mobileWidth) {
    width: calc(100% - ((100% - #{$offerCardWidth}) / 2));
  }
}

.filterContainer {
  display: flex;
  align-items: baseline;
  margin-right: 58px;
  flex-direction: row;
  @media screen and (max-width: $tabletWidth) {
    padding: 8px;
  }
}

.filter {
  width: 160px;
  margin-left: 15px;
  flex-direction: row;
  justify-self: flex-end;
}

.searchContainer {
  align-self: flex-start;
  @media screen and (min-width: $tabletWidth) {
    width: 288px;
  }
}
