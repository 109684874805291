@import "theme/colors";

.container {
  margin: 24px 0 36px;
  display: flex;
  flex-direction: column;
  flex-basis: 120px;
  flex-shrink: 0;
}

.container--error {
  margin-bottom: 15px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  textarea {
    flex-basis: auto !important;
    overflow: auto;
  }
}

textarea {
  font-family: "Inter", sans-serif;
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  background: $inputBackground;
  color: $darkBlue;
  border-radius: 8px;
  border: 1px solid $inputBorder;
  resize: none;
  overflow: auto;

  &.error {
    border-color: $error;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $inputPlaceholder;
  }

  &:-ms-input-placeholder {
    color: $inputPlaceholder !important;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.header {
  display: flex;
  flex-direction: row;
}

.charCounter {
  font-size: 14px;
  line-height: 24px;
  flex: 1;
  align-items: center;
  text-align: right;
  color: $darkGrey;
}

.label {
  flex: 1;
  margin-bottom: 20px;

  &.error {
    color: $error;
  }
}

.errorMessage {
  color: $error;
  padding-top: 6px;
}
