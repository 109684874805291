.card {
  margin: 16px;
  padding: 6px;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  padding: 6px;
}

.dropdownHeading {
  padding: 6px 6px 0px 24px;
  cursor: default;
}
